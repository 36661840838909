// Stripes

$stripe-color: rgba(208, 220, 241, 0.03);
$stripe-color2: rgba(0, 0, 0, 0.05);

.diagonal-stripes {
    background: repeating-linear-gradient(
        45deg,
        $stripe-color,
        $stripe-color 10px,
        $stripe-color2 10px,
        $stripe-color2 20px
    );
}
