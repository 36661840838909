.text-toggle {
    padding: 3px 3px;
    border-radius: 30px;
    display: inline-block;
}

.text-toggle [name^='TextToggle-'] {
    display: none;
}

.text-toggle input[type='radio'] + label {
    // background-color: #2673a7;
    // color: white;
    // padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
}

// .text-toggle input[type='radio']:checked + label {
//     background-color: white;
//     color: #00008b;
// }
