.full-page-background {
  height: calc(100vh - 95px);
    background: linear-gradient(to bottom,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.7) 100%),
        no-repeat center center fixed;
      background-size: cover;
      position: relative;
  
}

/* .full-page-background::after { */
  /* content: ""; */
  /* position: absolute; */
  /* width: 100%; */
  /* height: 100%; */
  /* backdrop-filter: blur(5px); */
  /* apply the blur */
  /* pointer-events: none; */
  /* make the overlay click-through */
/* } */